//** Color scheme via https://coolors.co

$brand-primary:                 darken(#E6AA68, 16.5%);
$body-bg:                       lighten($brand-primary, 8%);
$castanet-main-container-bg:    #FFF9CC;
$castanet-middle-container-bg:   #fff;
$castanet-sidebar-bg:            $brand-primary;
$link-color:                     $brand-primary;

//== Pagination
//
//##

$pagination-color:                     $link-color;
$pagination-bg:                        #fff;
$pagination-border:                    #ddd;

$pagination-hover-color:               $link-hover-color;
$pagination-hover-bg:                  $gray-500;
$pagination-hover-border:              #ddd;

$pagination-active-color:              #fff;
$pagination-active-bg:                 $brand-primary;
$pagination-active-border:             $brand-primary;

$pagination-disabled-color:            $gray-600;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;
